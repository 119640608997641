import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageProvider } from '../providers/StorageProvider';
import { catchError, map, Observable, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private storage: StorageProvider) { }

  /* intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const jwt = this.storage.get('JWT_TOKEN');

    let headers;

    if (jwt) {
      headers = new HttpHeaders({
        'Authorization': 'Bearer ' + jwt,
        //'Accept-Encoding': 'gzip, deflate'
        'Content-Type': 'application/json'
      });
    } else {
      headers = new HttpHeaders({
        //'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWV9.TJVA95OrM7E2cBab30RMHrHDcEfxjoYZgeFONFh7HgQ',
        //'Accept-Encoding': 'gzip, deflate'
        'Content-Type': 'application/json'
      });

    }
    
    const reqClone = req.clone({
      headers
    });
    
    //! Antes de Produccion Comentar esto
    if (!environment.production) {
      console.log('Request http: ', req.body);
    }
    
    return next.handle(reqClone)
      .pipe(
        timeout(40000),
        map(resp => this.getResponse(resp)),
        catchError(this.manejaError),
      );
      
      //timeout(20000),

  } */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.storage.get('JWT_TOKEN');
    let headers;

    // Evitar agregar el token si la URL es la de la API de Google Vision
    if (req.url.includes('vision.googleapis.com')) {
      return next.handle(req);  // Ignorar la petición, no agregar cabeceras personalizadas
    }

    if (jwt) {
      headers = new HttpHeaders({
        'Authorization': 'Bearer ' + jwt,
        'Content-Type': 'application/json'
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
    }

    const reqClone = req.clone({ headers });

    //! Antes de Produccion Comentar esto
    if (!environment.production) {
      console.log('Request http: ', req.body);
    }

    return next.handle(reqClone).pipe(
      timeout(5000),  // Opcional: Agregar timeout
      catchError((error: HttpErrorResponse) => {
        console.error('Error interceptado:', error);
        return throwError(error);
      })
    );
  }



  getResponse(resp: HttpEvent<any>) {

    //! Antes de Produccion Comentar esto
    if (!environment.production) {
      console.log('Response http:', resp);
    }

    return resp;
  }

  manejaError(error: HttpErrorResponse) {
    console.warn(error);
    return throwError(error);
  }


}
