import { Component, ViewEncapsulation } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StorageProvider } from './providers/StorageProvider';
import { LoginService } from './services/login.service';
import { personOutline, lockClosedOutline, eyeOutline, eyeOffOutline, logOutOutline, addCircleOutline, createOutline, trashOutline, addOutline, closeOutline, funnelOutline, cloudDownloadOutline, cloudUploadOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';


addIcons({
  /* 'person-outline': personOutline,
  'lock-closed-outline': lockClosedOutline,
  'eye-outline': eyeOutline,
  'eye-off-outline': eyeOffOutline,
  'log-out-outline': logOutOutline,
  'add-outline': addOutline,
  'add-circle-outline': addCircleOutline,
  'create-outline': createOutline,
  'trash-outline': trashOutline,
  'close-outline': closeOutline,
  'funnel-outline': funnelOutline,
  'cloud-upload-outline': cloudUploadOutline,
  'cloud-download-outline': cloudDownloadOutline */
});

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  // encapsulation: ViewEncapsulation.None,
  imports: [IonicModule, RouterModule, CommonModule],
})
export class AppComponent {

  constructor(
    private loginService: LoginService,
    private router: Router,
    private storage: StorageProvider,
    private menuCtrl: MenuController
  ) { }

  logout() {
    console.log('logout');
    this.storage.delete('JWT_TOKEN');
    this.storage.delete('MENU');
    this.storage.delete('LOGIN');
    this.loginService.setIsLoggedIn(false);
    // this.loginComponent.sidenavIsVisible=false;
    this.router.navigate(['/login']);
  }

  changePassword() {

  }

  /* closeMenu() {
    this.menuCtrl.toggle("1");  // Cierra el menú
  } */

  closeMenu() {
    this.menuCtrl.isOpen('1').then(isOpen => {
      if (isOpen) {
        this.menuCtrl.close('1');
      }
    });
  }

}
