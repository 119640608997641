import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadComponent: () => import('./main/main.page').then( m => m.MainPage)
  },
  {
    path: 'product-list',
    loadComponent: () => import('./product-list/product-list.page').then( m => m.ProductListPage)
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'orden-produccion',
    loadComponent: () => import('./orden-produccion/orden-produccion.page').then( m => m.OrdenProduccionPage)
  },
  {
    path: 'bingo-plate-cumplimiento',
    loadComponent: () => import('./bingo-plate-cumplimiento/bingo-plate-cumplimiento.page').then( m => m.BingoPlateCumplimientoPage)
  },
  {
    path: 'bingo-plate-cumplimiento-detalle',
    loadComponent: () => import('./bingo-plate-cumplimiento-detalle/bingo-plate-cumplimiento-detalle.page').then( m => m.BingoPlateCumplimientoDetallePage)
  },
  {
    path: 'bingo-pieza-modal',
    loadComponent: () => import('./bingo-pieza-modal/bingo-pieza-modal.page').then( m => m.BingoPiezaModalPage)
  },
  {
    path: 'cliente',
    loadComponent: () => import('./cliente/cliente.page').then( m => m.ClientePage)
  },
  {
    path: 'gestion-materiales',
    loadComponent: () => import('./gestion-materiales/gestion-materiales.page').then( m => m.GestionMaterialesPage)
  },
  {
    path: 'pieza-aspa-form-modal',
    loadComponent: () => import('./gestion-materiales/pieza-aspa-form-modal/pieza-aspa-form-modal.page').then( m => m.PiezaAspaFormModalPage)
  },
  {
    path: 'seccion-aspa-form-modal',
    loadComponent: () => import('./gestion-materiales/seccion-aspa-form-modal/seccion-aspa-form-modal.page').then( m => m.SeccionAspaFormModalPage)
  },
  {
    path: 'subseccion-aspa-form-modal',
    loadComponent: () => import('./gestion-materiales/subseccion-aspa-form-modal/subseccion-aspa-form-modal.page').then( m => m.SubseccionAspaFormModalPage)
  },
  {
    path: 'modelo-aspa-form-modal',
    loadComponent: () => import('./gestion-materiales/modelo-aspa-form-modal/modelo-aspa-form-modal.page').then( m => m.ModeloAspaFormModalPage)
  },
  {
    path: 'orden-produccion-form',
    loadComponent: () => import('./orden-produccion/orden-produccion-form/orden-produccion-form.page').then( m => m.OrdenProduccionFormPage)
  },
  {
    path: 'cliente-form',
    loadComponent: () => import('./cliente/cliente-form/cliente-form.page').then( m => m.ClienteFormPage)
  },
  {
    path: 'gestion-bingo-plate',
    loadComponent: () => import('./gestion-bingo-plate/gestion-bingo-plate.page').then( m => m.GestionBingoPlatePage)
  },
  {
    path: 'bingo-plate-subseccion-form-modal',
    loadComponent: () => import('./gestion-bingo-plate/bingo-plate-subseccion-form-modal/bingo-plate-subseccion-form-modal.page').then( m => m.BingoPlateSubseccionFormModalPage)
  },
  {
    path: 'bingo-plate-seccion-form-modal',
    loadComponent: () => import('./gestion-bingo-plate/bingo-plate-seccion-form-modal/bingo-plate-seccion-form-modal.page').then( m => m.BingoPlateSeccionFormModalPage)
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then( m => m.HomePage)
  },
];
