import { Injectable } from "@angular/core";
import { EAuthentication } from "../entidades/EAutenticacion";
import { ERequest } from "../entidades/ERequest";
import { StorageProvider } from "./StorageProvider";

@Injectable()
export class HeaderProvider {


    constructor(
        private storage: StorageProvider
        ) {

    }


    complete(request: ERequest<any>) {

        const json = this.storage.get('LOGIN');

        if (!json) {
            return request;
        }

        const data: EAuthentication = JSON.parse(json);
        request.header.aplicacion = "PCS-APP";
        request.header.canal = "WEB";
        request.header.dispositivo = "";
        request.header.usuario = data.nick;
        request.header.version = "1.0";
        request.header.fecha = data.fechaHora;
        request.header.ip = data.ip;
        request.header.guid = data.guid;
        request.header.idEmpresa = data.idEmpresa;
        request.header.idSucursal = data.idSucursal;
        request.header.idPlantaProduccion = data.idPlantaProduccion;
        request.header.idUsuario = data.idUsuario;
        request.header.idCaja = 0;
        request.header.idRol = data.idRol;
        request.header.correo = data.correo;
        request.header.idLineaProduccion = data.rol.idLineaProduccion;
        request.header.idTipoLineaProduccion = data.rol.idTipoLineaProduccion;
        
        return request;
    }

}

