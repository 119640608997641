import { Injectable } from "@angular/core";

@Injectable()
export class StorageProvider {

    /**
     * Permite guardar información en caché
     * @param nombre
     * @param valor 
     */
    add(nombre: string, valor: string) {
        localStorage.setItem(nombre, valor);
    }

    get(nombre: string) {
        return localStorage.getItem(nombre);
    }

    delete(nombre: string) {
        localStorage.removeItem(nombre);
    }

    clearAll() {
        localStorage.clear();
    }
}

