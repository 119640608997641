import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';  // <- Asegúrate de importar esto

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { StorageProvider } from './app/providers/StorageProvider';
import { HeaderProvider } from './app/providers/HeaderProvider';
import { HttpInterceptorService } from './app/services/http-interceptor.service';
import { FormsModule } from '@angular/forms';
import { importProvidersFrom } from '@angular/core';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { IonicModule } from '@ionic/angular';

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideHttpClient(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    importProvidersFrom([
      BrowserAnimationsModule,
      HttpClientModule,
      FormsModule,
      IonicModule
    ]),
    StorageProvider,
    HeaderProvider,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }, provideAnimationsAsync(),
  ],
});
