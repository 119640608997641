import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ERequest } from '../entidades/ERequest';
import { EAuthentication } from '../entidades/EAutenticacion';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(private http: HttpClient) { }

  Auth(object: ERequest<any>) {
    return this.http.post<EAuthentication>(environment.urlBase + 'v1.0/Login/Auth', object);
  }

  setIsLoggedIn(value: boolean): void {
    this.isLoggedInSubject.next(value);
  }
}
