<ion-app>
  <ion-menu side="start" menuId="1" contentId="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>BingoDigital</ion-title>
        <ion-buttons slot="end">
          <ion-menu-toggle>
            <ion-button>
              <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle>
          <ion-item class="menu-item" [routerLink]="['/orden-produccion']">
            <ion-label>Ordenes de producción</ion-label>
          </ion-item>
          <ion-item class="menu-item" [routerLink]="['/bingo-plate-cumplimiento']">
            <ion-label>Bingo Plate Cumplimiento</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/gestion-materiales']">
            <ion-label>Gestión de Materiales</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/gestion-bingo-plate']">
            <ion-label>Gestión de Bingo Plate</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/cliente']">
            <ion-label>Clientes</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/reporte-cumplimiento-bingo']">
            <ion-label>Reporte Bingo</ion-label>
          </ion-item>
          <!-- <ion-item [routerLink]="['/cliente']">
            <ion-label>Usuarios</ion-label>
          </ion-item> -->
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-list>
        <!-- <ion-item button (click)="changePassword()">
          <ion-label>
            <ion-icon slot="start" name="lock-closed-outline"></ion-icon> Cambiar Contraseña
          </ion-label>
        </ion-item> -->
        
        <ion-menu-toggle>
          <ion-item button (click)="logout()">
            <ion-icon slot="start" name="log-out-outline"></ion-icon> Cerrar Sesión
          </ion-item>
        </ion-menu-toggle>

        <!-- <ion-item button (click)="logout()">
          <ion-icon slot="start" name="log-out-outline"></ion-icon> Cerrar Sesión
        </ion-item> -->
      </ion-list>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>